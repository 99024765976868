.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.video-player {
  width: 100%;
}

.barrage-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* 彈幕不影響點擊 */
  overflow: hidden;
}

.barrage {
  position: absolute;
  white-space: nowrap;
  color: white;
  text-shadow: 1px 1px 2px black;
  animation: moveBarrage 14s linear forwards;
}

/* .barrage-input {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
} */

.barrage-layer.fullscreen {
  width: 100vw;
  height: 100vh;
}

@keyframes moveBarrage {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }
}
