@keyframes bounce {
  0%{
    transform: scale(0);
  }
  20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.9);
  }
}